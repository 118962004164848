.footer {
  display: none;
}

@media screen and (max-width: 450px) {
  .footer {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 150;
  }
}
