.dots {
  position: relative;
  bottom: 45px;
}

@media screen and (max-width: 450px) {
  .slider {
    /* display: none; */
  }
}
