.subnav {
    position: fixed;
    left: 0;
    top: 64px;
    width: 100%;
    align-items: content;
    padding: 5px;
    font-family: "Nunito";
    z-index: 90;
    /* box-shadow: 0 4px 8px 0 rgba(34, 34, 34, 0.1); */
  }
  /* @media (min-width: 2001px) {
    .subnav{
      width: 2000px;
      left: 50%;
      transform: translateX(-50%);
    }
  } */
  .xscrollbox {
    max-width: 600px;
  }
  
  .xscroll::-webkit-scrollbar {
    display: none;
  }
  
  .sortbtn {
    background-color: red;
  }
  
  .margin {
    margin-right: 310px;
  }
  
  .menulist {
    width: 800px;
    height: 500px;
    
  }
  
  
  @media screen and (max-width: 1250px) {
    .margin {
      margin-right: 0;
    }
  
    .xscrollbox {
      max-width: 250px;
    }
  
    .menulist {
      width: 900px;
    }
  }
  
  @media screen and (max-width: 900px) {
    .menulist {
      width: 700px;
      height: 500px;
    }
  }
  
  @media screen and (max-width: 450px) {
    .subnav {
        position: fixed;
        left: 0;
        top: 64px;
        width: 100%;
        align-items: content;
        /* padding: 5px; */
        font-family: "Nunito";
        z-index: 90;
        /* box-shadow: 0 4px 8px 0 rgba(34, 34, 34, 0.1); */
      }

      .filterbutton{
        display: none;    
      }
  }
  