.navbar {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); 
}

.searchbarwidth{
  width: 500px;

}

@media (min-width: 2001px) {
  /* .navbar {
    width: 2000px;
    left: 50%;
    transform: translateX(-50%);
  } */
}
.searchhide {
  display: none;
}
.iconbtn {
  display: none;
}
.ltext {
  display: block;
}

.inputbox {
  display: none;
}
.linputbox {
  display: block;
}

.searchbar {
  width: 500px;
  left: 10%;
  margin-top: 5px;
}
.searchbar::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 1405px) {
  .searchbarwidth{
    width: 500px;
  
  }
}


@media screen and (max-width: 1413px) {
  .searchbarwidth{
    width: 400px;
  
  }
}
@media screen and (max-width: 1330px) {
  .searchbarwidth{
    width: 350px;
  
  }
}

@media screen and (max-width: 1265px) {
  .searchbarwidth{
    width: 250px;
  
  }
}

/* @media screen and (max-width: 1250px) {
        .searchbarwidth{
        width: 350px;
      
      }
} */

@media screen and (max-width: 1165px) {
  .ltext {
    display: none;
  }
  .iconbtn {
    display: block;
  }
  .inputbox {
    display: none;
  }
  .linputbox {
    display: block;  
      }
  .searchbarwidth{
   width: 600px; 
    }
  .searchbar {
    margin: auto;
    margin-top: 5px;
    
  }
}

@media screen and (max-width: 900px) {
  .searchbarwidth{
    width: 400px;
  
  }
}
@media screen and (max-width: 626px) {
  .searchbarwidth{
    width: 300px;
  
  }
}

@media screen and (max-width: 450px) {
  .snav {
    display: none;
  }
  .inputbox {
    display: none;
  }
  .linputbox {
    display: none;
    width: 300px;  
  }
  .searchhide {
    display: block;
  }
  .searchbar {
    width: 80%;
    margin: auto;
    margin-top: 5px;
  }
}
