.listbox {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px 20px;
  margin: auto;
  max-width: 1050px
}
.iconImg2 {
  height: auto;
  width: 58px;
  transition: transform 0.3s ease; /* Add transition for smooth effect */

}
.iconImg2:hover {
  transform: scale(1.1); /* Adjust scale value as needed */
  
}
.adbanner {
  grid-column: 1 / -1; /* Makes the ad span all columns if inside a grid */
  width: 100%; /* Ensures full width */
}
  
.iconImg {
  height: auto  ;
  width: 55px;
}


.modal {
  overflow-y: auto;

  width: 80%;

  margin: auto;
  /* margin-top: 70px; */
}

.modal::-webkit-scrollbar {
  display: none;
}

.image {
  height: 146px;
  width: 100%;
}
.icon {
  position: relative;
  bottom: 37px;
  width: 100%;
  margin: auto;
}

.savebtn {
  width: 113.61px;
}

.dec {
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.lhide {
  display: none;
}

@media (max-width: 1400px) {
  .modal {
    width: 90%;

  }
}

@media (max-width: 1165px) {
    .listbox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2px 20px;
    margin: auto;
    max-width: 1050px
  }

  .modal {
    width: 100%;

  }

}

@media screen and (max-width: 920px) {
  .listbox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 5px 20px; */
    margin: auto;
  }
}



@media screen and (max-width: 770px) {
  .modal {
    width: 100%;
    /* margin-top: 50px; */
  }

  .listbox {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* gap: 5px 20px; */
    margin: auto;
  }
}

@media screen and (max-width: 450px) {
  .listbox {
    /* display: grid; */
    grid-template-columns: repeat(1 1fr);
    /* gap: px 5px; */
    margin: auto;
  }
  .shide {
    display: none;
  }

  .lhide {
    display: block;
  }

  .dec {
    width: 100%;
    margin-bottom: 10px;
    font-size: 12px;
  }
  .iconImg2 {
    height: auto;
    width: 48px;
  }

  .modal {
    width: 100%;

    /* margin: auto; */
    /* margin-top: 60px; */
  }
}
