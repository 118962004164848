.collection {
  width: 90%;
}

.cgrid {
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 95%;
}
.cinput {
  width: 35%;
}

.cgrid > div {
  height: 265px;

  width: 100%;
  background: rgba(59, 137, 182, 0.1);

  border-radius: 5px;
}

.icongrid {
  display: grid;
  grid-template-columns: repeat(3, 42px);
  gap: 5px;

  width: fit-content;

  margin-top: 10px;
}
/* .colldesc {
  font-size: 25px;

  text-align: justify;
  line-height: 42px;
} */

@media screen and (max-width: 1025px) {
  .collection {
    width: 70%;
  }

  .cgrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
    margin: auto;
    margin-top: 30px;
  }

  .cinput {
    width: 45%;
  }
}

@media screen and (max-width: 850px) {
  .collection {
    width: 90%;
  }

  .cgrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 450px) {
  .collection {
    width: 95%;
    margin-top: 20px;
  }

  .cgrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 70%;
    margin: auto;
    margin-top: 25px;
  }

  .cinput {
    width: 70%;
  }

  /* .colldesc {
    font-size: 20px;

    text-align: justify;
    line-height: 30px;
  } */
}
