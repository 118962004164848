body {
  height: 100%;
  
}

html {
  scroll-behavior: smooth;
}
.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  color: initial !important;
}
.slick-prev:before,
.slick-next:before {
  color: initial !important;
}