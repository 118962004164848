* {
  font-family: "Nunito";
}

.iframeTool {
  object-fit: "cover";
  border-radius: "5px";
  height: "455px";
}

.titlehide2 {
  display: none;
}

.toolbox {
  display: flex;
  gap: 60px;
  margin-left: 60px;
  margin-right: 20px; 
}

.mobileview{
  display: none;
}


.leftscroll {
  gap: 50px;
}
.CoverImg {
  width: 85%;
  
}
.tscroll::-webkit-scrollbar {
  display: none;
}

.imagescroll {
  width: 100%;
}

.imagescroll::-webkit-scrollbar {
  display: none;
}

.tnav {
  display: flex;
}

.rtool {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.tnav {
  max-width: 100%;
}

.sbag {
  position: relative;
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x-mandatory;
}

.sbag::-webkit-scrollbar {
  display: none;
}

.vbutton {
  width: 147px;
  /* border: 1px solid red; */
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.vbutton:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.visit {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}
.rating {
  display: flex;
  justify-content: space-between;
}


@media screen and (max-width: 1500px) {
  .toolbox {
    display:flex;
    gap: 40px;
    margin-left: 30px;
    margin-right: 30px;
    /* width:700px; */
  }

  .tnav {
    max-width: 70%;
  }

  .mobileview{
    display: none;
  }

  .iframeTool {
    object-fit: "cover";
    border-radius: "5px";
    height: "455px";
    width:100%;
  }
  
  .titlehide2 {
    display: none;
  }
  
  .toolbox {
    display: flex;
    gap: 60px;
    margin-left: 60px;
    margin-right: 20px; 
  }
  
/* 
  .imagescroll {
    width: 70%;
  } */

}

@media screen and (max-width: 1025px) {
  .toolbox {
    display: flex;
    gap: 40px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .mobileview{
    display: none;
  }

  .tnav {
    max-width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .toolbox {
    display: flex;
    gap: 30px;
    margin-left: 20px;
    margin-right: 20px;
  }  

  .mobileview{
    display: none;
  }

  .CoverImg {
    width: 100%;
  }

  .list {
    width: 400px;
  }

  .tnav {
    max-width: 100%;
  }

  .rating {
    display: grid;
    gap: 20px;
  }

  .hidetext {
    display: none;
  }
}

@media screen and (max-width: 768px) {

  
  .leftscroll {
    gap: 0px;
    text-align: left;
    align-items: left;
  }

  .iframeTool {
    object-fit: "cover";
    border-radius: "5px";
    height: "455px";
   
  }
  

  .CoverImg {
    width: 100%;
  }



  .right {
    margin-top: 20px;
  }

  .tnav {
    width: 100%;
  }

  .left {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .mobileview{
    display: flex;
  }
  .rtool {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .riviewfilter {
    flex-direction: column;
    gap: 25px;
  }

  .rating > Button {
    margin-top: 20px;
  }

  .rating > div {
    margin-top: 10px;
  }

  .toolbox {
    flex-direction: column;
    width: 90%;
    margin: auto;
  }

  .list {
    width: 90%;
  }

  .fprice {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: left;
  }

  .fprice > div {
    width: 50%;
    text-align: left;
  }

  .fwork {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: left;
  }

  .fwork > div {
    width: 50%;
    text-align: left;
  }

  .fcate {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: left;
  }

  .fcate > div {
    width: 50%;
    text-align: left;
  }

  .media {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: left;
  }

  .media > div {
    width: 50%;
    text-align: left;
  }

  .titlehide2 {
    display: block;
  }

  .titlehide1 {
    display: none;
  }

  .vbutton {
    width: 152px;
    margin-top: 38px;
    margin-bottom: 38px;
  }

  .visit {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 0px;
  }
}

@media screen and (max-width: 768px) {
  .tnav {
    width: 400px;
  }
}
@media screen and (max-width: 500px) {
  .leftscroll {
    gap: 0px;
    text-align: left;
    align-items: left;
  }

  .iframeTool {
    object-fit: "cover";
    border-radius: "5px";
    height: "455px";
   
  }

  .CoverImg {
    width: 100%;
  }

  .right {
    margin-top: 20px;
  }

  .tnav {
    width: 300px;
  }

  .left {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .rtool {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .riviewfilter {
    flex-direction: column;
    gap: 25px;
  }

  .rating > Button {
    margin-top: 20px;
  }

  .rating > div {
    margin-top: 10px;
  }

  .toolbox {
    flex-direction: column;
    width: 90%;
    margin: auto;
  }

  .list {
    width: 320px;
  }

  .fprice {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: left;
  }

  .fprice > div {
    width: 50%;
    text-align: left;
  }

  .fwork {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: left;
  }

  .fwork > div {
    width: 50%;
    text-align: left;
  }

  .fcate {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: left;
  }

  .fcate > div {
    width: 50%;
    text-align: left;
  }

  .media {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: left;
  }

  .media > div {
    width: 50%;
    text-align: left;
  }

  .titlehide2 {
    display: block;
  }

  .titlehide1 {
    display: none;
  }

  .vbutton {
    width: 152px;
    margin-top: 38px;
    margin-bottom: 38px;
  }

  .visit {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 0px;
  }
}

.mediaSlider {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  cursor: grab;
  scroll-snap-type: x proximity;
  -webkit-overflow-scrolling: touch;
}

.mediaSlider:active {
  cursor: grabbing;
}

.mediaSlider::-webkit-scrollbar {
  display: "none";
}

/* Add smooth transitions */
.mediaSlider > * {
  transition: transform 0.3s ease-out;
}
