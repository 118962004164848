/* @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap'); */

body {
  font-family: "Nunito";
  box-sizing: border-box;
  position: relative;
}

/* @media screen and (max-width: 450px) {
  body::-webkit-scrollbar {
    display: none;
  }
} */

@media (min-width: 2001px) {
   .container{
    display: flex;
    justify-content: center;
    margin: 0px auto;
   }
}
.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  color: initial !important;
}
.slick-prev:before,
.slick-next:before {
  color: initial !important;
}