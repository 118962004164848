.openslide {
  margin-left: 150px;
  margin-top: 50px;
}
.closeslide {
  margin-left: 45.5px;
  /* margin-left: 20px; */
  margin-top: 50px;
}


.opengap {
  /* gap: 16px; */
  gap: 26px;
}
.closegap {
  /* gap: 35px; */
  gap: 75px;
}

.landingbox {
  width: 100%;
  max-width: 1050px;
  margin: 20px auto; /* This will center the .landingbox and give it a top margin of 20px */
}

/* .landingbox {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
} */


.smallScreenList{
  display: "none";
}


@media screen and (max-width: 1025px) {
  .opengap {
    gap: 20px;
  }
  .closegap {
    gap: 70px;
  }
  .landingbox {
    width: 90%;
    margin: auto;
    margin-top: 20px;
  }
}

@media screen and (max-width: 900px) {
  .opengap {
    gap: 0px;
  }
  .closegap {
    gap: 60px;
  }
}

@media screen and (max-width: 450px) {
  .openslide {
    margin-left: 0px;
    margin-top: 10px;
  }
  .closeslide {
    margin-left: 0px;
    margin-top: 10px;
  }

  .slide {
    display: none;
  }
  .closegap {
    gap: 0px;
  }

  .landingbox {
    width: 90%;
    margin: auto;
    margin-top: 0px;
  }
}