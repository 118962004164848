.featured {
  width: 100%;

  text-align: left;
}

.landingRight {

  width: 255px; 
  margin-right: 15px;
  /* margin-left: -10px; */
  
}
.desc1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.grid_icon {
  display: grid;

  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);
  gap: 0px;
  border-radius: 5px;
  width: fit-content;
  padding: 10px;

  align-items: center;

  justify-content: center;
}

/* @media screen and (max-width: 1025px) {
  .landingRight {
    display: none;
    margin-right: 0;
  }
} */

/* @media screen and (max-width: 1500px) {
  .landingRight {
    display: none;
    margin-right: 0;
  }
}

@media screen and (max-width: 920px) {
  .landingRight {
    display: none;
  }
}

@media screen and (max-width: 450px) {
} */
