.gridbox {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three items per row */
  gap: 40px; /* Space between grid items */
  justify-content: center; /* Centers items horizontally */
  align-items: center; /* Centers items vertically */
  margin: auto;
}
.gridbox > * {
  max-width: 300px;
  margin: auto;
}

.icon {
  position: relative;
  bottom: 30px;
  width: 100%;
  margin: auto;
}
.coveImage::before {
  content: 'More Info';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; /* Allow clicks to pass through */
}

.coveImage:hover::before {
  opacity: 1;
}


.coveImage {
  height: 100%;
  object-fit: scale-down;
  cursor: pointer; /* Indicate clickable */
}

.savebtn {
  width: 113.61px;
}
.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.dec {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.modal {
  overflow-y: auto;
  width: 80%;
  margin: auto;
  margin-top: 70px;
  
}

.modal::-webkit-scrollbar {
  display: none;
}


@media (max-width: 1400px) {
  .modal {
    width: 90%;

  }
}

@media (max-width: 1025px) {
  .gridbox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    margin: auto;
  }

  .modal {
    width: 90%;
  }
}

@media (max-width: 900px) {
  .gridbox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 35px;
    margin: auto;
  }

  .modal {
    width: 90%;
  }
}

@media screen and (max-width: 450px) {
  .gridbox {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
  .savebtn {
    width: 113.61px;
  }

  .modal {
    margin-top: 60px;
    width: 100%;
  }

  .coveImage {
    height: 100%;
    object-fit: scale-down;
  }
}
.coveImage {
  position: relative;
  overflow: hidden;
}

