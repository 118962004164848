.sidebar {
  position: fixed; /* Keeps the sidebar fixed */
  left: 0;
  top: 0; /* Aligns to the top to avoid being cut off on small screens */
  width: 164px; /* Assuming you want a fixed width, adjust as necessary */
  box-sizing: border-box;
  overflow-y: auto; /* Keeps the scrollbar if content overflows */
  justify-content: center;
  align-items: center;
  height: 100%;
  
  z-index: 10; /* Make sure it's above other content */
}

/* Hide scrollbar for closed sidebar but allow scrolling */
.sidebar.closed::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Webkit browsers */
}

/* For Firefox */
.sidebar.closed {
  scrollbar-width: none;
}

/* For IE and Edge */
.sidebar.closed {
  -ms-overflow-style: none;
}

.checked {
  border: 2px solid blue; /* Example: Highlight with a blue border */
}

.scroll {
  height: 100%;
  margin-top: 100px;
}

.sidebar::-webkit-scrollbar {
  width: 5px;
 }

.sidebar::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: 10px;
}

.innerbox {
  margin: auto;
}

.itext {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 1000px) {
  .innerbox {
    gap: 5px;
    line-height: 1;
  }
}

@media screen and (max-width: 450px) {
  .sidebar {
    display: none;
  }
}
